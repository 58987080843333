<template>
  <div id="accident">
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs defaultActiveKey="0"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane key="0"
                    tab="生产安全事故快报"> </a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <ExportDownload :isView="isView"
                        :url="imgUrl"
                        :single="false"
                        width="50%"
                        :file-list="templateList"
                        @exportForm="exportForm"
                        @download="download" />
      </template>
    </Pane>

    <div class="container">
      <a-form :form="form"
              @submit="handleSubmit"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
              :colon="false">
        <a-row>
          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="当前项目"
                         class="default-highlight"
                         :label-col="{ span: 8 }"
                         :wrapper-col="{ span: 12 }">
              <a-input :disabled="true"
                       :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="工程名称"
                         class="default-highlight">
              <a-input :disabled="true"
                       :placeholder="projectName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="项目名称">
              <a-input v-decorator="[
              'constructionName',
              { rules:[{ required: true, message: '请输入' }]}
              ]"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="事故时间"
                         :label-col="{ span: 8 }"
                         :wrapper-col="{ span: 12 }">
              <a-date-picker placeholder=""
                             v-decorator="[
                'accTime',
                { rules:[{ required: true, message: '请选择' }]}
                ]"
                             style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="事故地点"
                         :label-col="{ span: 8 }"
                         :wrapper-col="{ span: 12 }">
              <a-input v-decorator="[
              'accLocation',
              { rules:[{ required: true, message: '请输入' }]}
              ]"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="事故单位">
              <a-input v-decorator="[
              'accOrg',
              { rules:[{ required: true, message: '请输入' }]}
              ]"></a-input>
            </a-form-item>
          </a-col>

          <a-col :span="20"
                 :offset="2"
                 style="margin-bottom: 20px;">
            <div class="card">
              <div class="title">
                事故现场负责人
              </div>
              <a-row type="flex"
                     justify="center">
                <a-col :span="8">
                  <a-form-item label="姓名">
                    <a-input v-decorator="['accSceneRpName']"></a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="8"
                       :offset="2">
                  <a-form-item label="电话">
                    <a-input v-decorator="['accSceneRpPhone']"></a-input>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="20"
                 :offset="2"
                 style="margin-bottom: 20px;">
            <div class="card">
              <div class="title">
                事故单位负责人
              </div>
              <a-row type="flex"
                     justify="center">
                <a-col :span="8">
                  <a-form-item label="姓名">
                    <a-input v-decorator="['accOrgRpName']"></a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="8"
                       :offset="2">
                  <a-form-item label="电话">
                    <a-input v-decorator="['accOrgRpPhone']"></a-input>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="20"
                 :offset="2"
                 style="margin-bottom: 20px;">
            <div class="card">
              <div class="title">
                事故详情
              </div>
              <a-row type="flex"
                     justify="center">
                <a-col :span="6">
                  <a-form-item label="死亡人数"
                               :label-col="{ span: 8 }"
                               :wrapper-col="{ span: 8 }">
                    <a-input v-decorator="['deadNum']"></a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="失踪人数"
                               :label-col="{ span: 8 }"
                               :wrapper-col="{ span: 8 }">
                    <a-input v-decorator="['missingNum']"></a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="重伤人数"
                               :label-col="{ span: 8 }"
                               :wrapper-col="{ span: 8 }">
                    <a-input v-decorator="['injuredNum']"></a-input>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
          </a-col>

          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="事故简要经过">
              <a-input :auto-size="{ minRows: 3 }"
                       v-decorator="[
              'accProcessDesc',
              { rules:[{ required: true, message: '请输入' }]}
              ]"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="事故现场情况及救援采取的主要措施"
                         class="label-style"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <a-textarea :auto-size="{ minRows: 5 }"
                          v-decorator="[
              'accSceneDesc',
              { rules:[{ required: true, message: '请输入' }]}
              ]"></a-textarea>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="其他情况">
              <a-textarea :auto-size="{ minRows: 5 }"
                          v-decorator="[
              'accRemark',
              { rules:[{ required: true, message: '请输入' }]}
              ]"></a-textarea>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <span slot="label"
                    class="">附件</span>
              <div class="link-list"
                   v-decorator="[
                'file',
                { rules:[{ required: true, message: '请上传附件' }]}
                ]">
                <a-checkbox-group :value="selectedFileList"
                                  @change="onSelectFile">
                  <div class="link"
                       v-for="item in fileList"
                       :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank"
                         download
                         :href="item.completePath">{{
                        item.name
                      }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>
                  <div class="control"
                       @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button html-type="submit"
                      type="primary"
                      v-if="!isView">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import ExportDownload from "@/views/epc/components/ExportDownload";
import FileUpload from "@/components/file-upload";
import { saveAs } from "file-saver";

import { fetchDetail } from "@/api/epc";
import {
  add,
  edit,
  fetchDetail as fetchFileDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/construction/accident";

import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "construAcci",
  components: {
    FileUpload,
    ExportDownload,
  },
  data() {
    return {
      imgUrl: require("@/assets/epc/approvalFlow/4-9accident.png"),

      stage: "epc_construction_security_accident",
      isView: false,

      form: this.$form.createForm(this),
      pid: "",
      id: "",
      projectName: "",
      designCode: "",

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
    templateList() {
      //导出文件
      let list = [
        {
          value: "construction_security_accident_report",
        },
        {
          value: "construction_security_accident_level",
        },
      ];

      list.forEach((item, index) => {
        item.id = index;
        item.name = this.findSingleDictName("epc_file_export_type", item.value);
      });
      return list;
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail(); // 从文件列表进入时，获取详情
    }
    this.getName(); // 获取工程名称，设计编号
  },
  methods: {
    getDetail() {
      fetchFileDetail({ id: this.id }).then((res) => {
        if (!res.id) return;

        this.form.setFieldsValue({
          accLocation: res.accLocation,
          accOrg: res.accOrg,
          accOrgRpName: res.accOrgRpName,
          accOrgRpPhone: res.accOrgRpPhone,
          accProcessDesc: res.accProcessDesc,
          accRemark: res.accRemark,
          accSceneDesc: res.accSceneDesc,
          accSceneRpName: res.accSceneRpName,
          accSceneRpPhone: res.accSceneRpPhone,
          accTime: moment(res.accTime),
          constructionName: res.constructionName,
          deadNum: res.deadNum,
          injuredNum: res.injuredNum,
          missingNum: res.missingNum,
        });

        if (res.attachmentList) {
          this.form.setFieldsValue({
            file: res.attachmentList,
          });
          this.fileList = res.attachmentList;
        }
      });
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          const accTime = values.accTime.format("YYYY-MM-DD");
          delete values.accTime;
          delete values.file;

          if (!this.id) {
            console.log("新增");
            console.log(this.fileList);
            console.log(values);

            add({
              ...values,
              accTime,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          } else {
            edit({
              ...values,
              id: this.id,
              historyId: this.hid,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },

    exportForm(item) {
      exportFile({ id: this.id, fileExportType: item.value })
        .then((blob) => {
          saveAs(blob, `${this.projectName}_${item.name}.docx`);
        })
        .catch();
    },
    download(item) {
      downloadTemplate(item.value)
        .then((blob) => {
          saveAs(blob, `${item.name}模版.docx`);
        })
        .catch();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}
.card {
  background-color: #fbfbfb;
  .title {
    padding: 5px 10px;
    background-color: #1890ff;
    display: inline-block;
    color: #fff;
    margin-bottom: 10px;
  }
}

.center {
  margin-top: 80px;
}
</style>

<style lang="less">
#accident {
  .label-style {
    label {
      white-space: pre-wrap;
      display: inline-block;
      line-height: 1.5em;
    }
  }
}
</style>
